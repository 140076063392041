import React from 'react'
import Title from '../Title/Title'
import Projects from '../Projects/Projects'
import GraphicData from '../../ServiceData/Graphic.json'

const BodyGraphicDesign = () => {
    return (
        <div>
            <div className="logoDesign d-flex-col-just-center" >

                {
                    GraphicData.map((eachGraphic, id) => {
                        return (

                            <section
                                className={` ${id === 1 ? 'logoDesign_section2' : 'logoDesign_section1'}`}
                                key={id}
                                data-aos="fade-left">
                                <Title titleName={eachGraphic.title} />

                                <div className=
                                    {` ${id === 1 ? 'logoDesign_section2-container' : 'logoDesign_section1-container'} 
                                
                                
                                logoDesign_section1-container d-flex-row-wrap-j-center-a-center`}>


                                    <div className="logoDesign_section1-txt">
                                        <h3 className='subtitle-two'>{eachGraphic.subtitle}</h3>
                                        <p className='text-p'>{eachGraphic.text}</p>
                                    </div>
                                    <div className="logoDesign_section1-img">
                                        <img src={eachGraphic.img} alt="Graphic" />
                                    </div>
                                </div>
                            </section>
                        )
                    })
                }



            </div>

            <Projects forWhatPage={'graphicDesign'} />
        </div >
    )
}

export default BodyGraphicDesign
