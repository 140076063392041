import React from 'react'
import Header from '../../Components/Header/Header'
import Paralax from '../../Components/Paralax/Paralax'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'
import BodyComponent from '../../Components/BodyComponent/BodyComponent'
import TopArrow from '../../Components/Top_Arrow/TopArrow'

const GraphicDesign = () => {

    return (
        <div className='bgColor'>


            <Header forWhatPage={'Graphic Design'} />
            <Paralax
                withSlogan={false}
                firstLine={'Designs'}
                secondLine={'that Elevate'}
                thirdLine={'Your Brand.'}
            />

            <BodyComponent bodyFor={'Graphic Design'} />


            <Contact />
            <Footer forWhatPage={'Graphic Design'} />
            <TopArrow />



        </div>
    )
}

export default GraphicDesign
