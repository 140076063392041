import React, { useState } from 'react'
import Title from '../Title/Title'
import emailjs from '@emailjs/browser';
import { Button, Form } from "react-bootstrap";
import Swal from 'sweetalert2'

const Contact = () => {

    const [formData, setFormData] = useState({
        from_name: '',
        from_email: '',
        from_message: ''
    });

    // Manejador de cambios para actualizar el estado con los valores de entrada
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Función para manejar el envío del formulario
    const sendEmail = (e) => {
        e.preventDefault();

        // Datos del formulario para enviar a EmailJS
        const dataToSend = {
            from_name: formData.from_name,
            from_email: formData.from_email,
            from_message: formData.from_message,
        };

        //console.log(dataToSend); // Verifica que los datos son correctos

        emailjs.send(
            'service_ciilg7c',
            'template_lzmordh',
            dataToSend,
            'XV-8v0Hhxb1QddVSp'
        )
            .then((result) => {
                console.log(result.text);
                Swal.fire({
                    title: 'Thank you!',
                    text: 'Message sent successfully.',
                    icon: 'success',
                    confirmButtonText: 'Ok!',
                    customClass: {
                        popup: 'mi-popup',
                    }
                });

                setFormData({
                    from_name: '',
                    from_email: '',
                    from_message: ''
                });

            }, (error) => {
                console.log(error.text);
                Swal.fire({
                    title: "Error!",
                    text: "Message could not be sent.",
                });
            });
    };

    return (
        <div className='contact' id='Contact' data-aos="fade-up" >
            <div className='contact_body' id='Contact'>
                <Title titleName={'Contact'} />

                <div className="contact_container d-flex-row-wrap-j-center ">
                    <div className="contact_txt d-flex-col">
                        <h3 className='subtitle-two'>
                            Let's stay connected
                        </h3>
                        <p className='text-p'>
                            We're eager to hear from you! Complete the form with your questions or suggestions, and we'll respond promptly. For a quicker reply, reach out to us via our social media channels. Your project is important to us, and we're here to help make it a reality.
                        </p>
                    </div>

                    <div className="contact_input">
                        <Form onSubmit={sendEmail}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    name="from_name"
                                    placeholder="Full Name"
                                    className='inputBox'
                                    value={formData.from_name}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="email"
                                    name="from_email"
                                    placeholder="Email"
                                    className='inputBox'
                                    value={formData.from_email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                    as="textarea"
                                    name="from_message"
                                    rows={3}
                                    placeholder="Message"
                                    className='inputBox'
                                    value={formData.from_message}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Send
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="contact-bg">
                <img src="Assets/contact-bg.svg" alt="contact bg" />
            </div>
        </div>
    )
}


export default Contact
