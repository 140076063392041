import React from 'react'
import TopArrow from '../../Components/Top_Arrow/TopArrow'
import Header from '../../Components/Header/Header'
import Paralax from '../../Components/Paralax/Paralax'
import BodyComponent from '../../Components/BodyComponent/BodyComponent'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'

const WebDesign = () => {
    return (
        <div className='webDesign bgColor'>

            <Header forWhatPage={'Web Design'} />
            <Paralax
                withSlogan={false}
                firstLine={'Websites that'}
                secondLine={'Stand Out and'}
                thirdLine={'Capture Attention.'}
            />

            <BodyComponent bodyFor={'Web Design'} />

            <Contact />
            <Footer forWhatPage={'Web Design'} />
            <TopArrow />

        </div>
    )
}

export default WebDesign
