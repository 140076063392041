import React, { useEffect, useState } from 'react'
import Title from '../Title/Title'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProjectData from '../../ServiceData/ProjectData.json';
import VideoComponent from '../VideoComponent/VideoComponent';

const Projects = ({ forWhatPage }) => {

    const [imageArray, setImageArray] = useState([])
    const [showVideo, setShowVideo] = useState(false)

    useEffect(() => {
        for (const eachProject of ProjectData) {

            if (forWhatPage === Object.keys(eachProject)[0]) {
                setImageArray(eachProject[forWhatPage])
            }
        }
        if (forWhatPage === 'home') {
            setShowVideo(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])






    const settings = {
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2500,
        autoplaySpeed: 2000,
        arrows: false,
        // cssEase: "linear"
    };

    return (
        <div className="project-bg">
            <div className='projects' id='Projects' data-aos="fade-up">

                <Title titleName={'Projects'} />

                <div className="projects-subtitle">
                    <h2 className='subtitle-two'>Achievements</h2>
                </div>

                <Slider {...settings}>
                    {imageArray.map((eachImg, id) => {
                        return (
                            <div key={id}>
                                <img src={eachImg.image} alt={`Imagen ${id}`} />
                            </div>
                        )
                    })}
                </Slider>
                {showVideo && <VideoComponent />
                }
            </div>

            <div className="my-bg">
                <img src="Assets/background2.svg" alt="background" />
            </div>
        </div >
    )
}

export default Projects
