import React from 'react'

const Title = ({ titleName }) => {
    return (
        <div className='title d-flex-a-center'>
            <div className="title_container d-flex-j-center">
                <img src="Assets/Iso_DigitalWave.svg" alt="Digital Wave iso" className='title_iso' />
                <h2 className='subtitle-one'>{titleName}</h2>
            </div>
        </div>
    )
}

export default Title
