import React, { useEffect, useState } from 'react'
import Title from '../Title/Title'
import BodyOne from '../BodyOne/BodyOne'
import BodyGraphicDesign from '../BodyGraphicDesign/BodyGraphicDesign'
import BodyWebDesign from '../BodyWebDesign/BodyWebDesign'
import BodyCommunity from '../BodyCommunity/BodyCommunity'

const BodyComponent = ({ bodyFor }) => {

    const [graphicDesignBody, setGraphicDesignBody] = useState(false)
    const [webDesignBody, setWebDesignBody] = useState(false)
    const [communityBody, setCommunityBody] = useState(false)



    useEffect(() => {
        if (bodyFor === 'Graphic Design') {
            setGraphicDesignBody(true)
        } else if (bodyFor === 'Web Design') {
            setWebDesignBody(true)
        } else if (bodyFor === 'Community Manager') {
            setCommunityBody(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    return (
        <div className='BodyComponent' data-aos='fade-right'>
            <Title titleName={bodyFor} />
            <BodyOne bodyFor={bodyFor} />

            {graphicDesignBody && <BodyGraphicDesign />}
            {webDesignBody && <BodyWebDesign />}
            {communityBody && <BodyCommunity />}
        </div>
    )
}

export default BodyComponent
