import React, { useEffect, useRef } from 'react';
import realVideo from './realVideo.mp4';


const VideoComponent = () => {
    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        // Configuración del Intersection Observer
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        video.play(); // Reproduce el video cuando es visible
                    } else {
                        video.pause(); // Pausa el video cuando no es visible
                    }
                });
            },
            {
                threshold: 0.5, // 50% del video debe ser visible para iniciar la reproducción
            }
        );

        // Observa el elemento de video
        if (video) {
            observer.observe(video);
        }
        // Limpieza del observer cuando el componente se desmonta
        return () => {
            if (video) {
                observer.unobserve(video);
            }
        };
    }, []); // Dependencia vacía para ejecutar useEffect solo una vez al montar

    return (
        <div className="VideoComponent">
            <div className='videoComponent_container'>
                <video
                    ref={videoRef}
                    controls
                    muted
                    className='videoComponent_container-video'
                >
                    <source src={realVideo} type="video/mp4" />
                    Tu navegador no soporta la reproducción de video.
                </video>
            </div>
        </div>
    )
}

export default VideoComponent
