import React, { useEffect, useState } from 'react'
import bodyOneData from '../../ServiceData/BodyOne_data.json'

const BodyOne = ({ bodyFor }) => {

    const [bodyData, setBodyData] = useState([])

    useEffect(() => {

        for (const eachSection of bodyOneData) {
            if (bodyFor === eachSection.bodyFor) {
                setBodyData(eachSection)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="bodyOne-bg">

            <div className='bodyOne d-flex-row-wrap-j-center'>

                <div className="sectionOne">
                    <h2 className='subtitle-two'>{bodyData.title}</h2>
                    <p className='text-p'>{bodyData.text}</p>
                </div>
                <div className="sectionTwo">
                    <img src={bodyData.img} alt={bodyData.title} />
                </div>

            </div>
            <div className="my-bg">
                <img src="Assets/background3.svg" alt="background" />
            </div>
        </div>
    )
}

export default BodyOne
