import React from 'react'
import Typewriter from 'typewriter-effect';


const Paralax = ({ withSlogan, firstLine, secondLine, thirdLine }) => {


    return (
        <div className='paralax d-flex-col' >

            <h1 className='d-flex-col paralax_title'>
                <span className='principal-color'>{firstLine} <br /> {secondLine}</span>
                <span className='third-line'>{thirdLine}</span>
            </h1>
            {withSlogan &&
                <div className="container">
                    <div className="text-box slogan">
                        <Typewriter
                            onInit={typewriter => {
                                typewriter
                                    .typeString('Connect your brand with the digital world.')
                                    .pauseFor(2000)
                                    .changeDeleteSpeed(100)
                                    .deleteAll()
                                    .typeString('Connect your brand with the digital world.')
                                    .start();
                            }}
                        />
                    </div>
                </div>

            }
            <div className="bg-images">

                <div className="square-bg" >
                    <img src="Assets/paralax_cuadrados.svg" alt="square background" />
                </div>

                <div className="arrow-bg" data-aos="fade-right">
                    <img src="Assets/paralax_flechas.svg" alt="arrow background" />
                </div>

                <div className="circle-bg" data-aos="fade-left" >
                    <img src="Assets/paralax_circulos.svg" alt="circle background" />
                </div>

            </div>
        </div >
    )
}

export default Paralax
