import React from 'react'
import Title from '../Title/Title'


const AboutUs = () => {



    return (
        <div className="aboutUs-bg">
            <div className='AboutUs' id='About_Us' data-aos="fade-up">
                <Title titleName={'About Us'} />
                <div className="AboutUs_body d-flex-col-just-center">
                    <div className="AboutUs_body-subtitle">
                        <h2 className='subtitle-two'>We are an Innovative Digital Agency</h2>
                    </div>
                    <div className="AboutUs_body-p">
                        <p className='text-p '>
                            Are you ready to transform your presence on social media? At Digital Wave, we specialize in taking your brand to the next level through innovative and personalized digital strategies. From creating captivating content to effective advertising campaigns, we optimize every aspect of your business or personal brand to maximize your impact and results. Connect with your audience like never before and watch your business grow!
                        </p>
                    </div>
                    <div className="AboutUs_body-img d-flex-j-center">
                        <img src="Assets/AboutUs-img.png" alt="About us pics" />
                    </div>
                </div>
            </div>
            <div className="my-bg">
                <img src="Assets/background1.svg" alt="background" />
            </div>
        </div>
    )
}

export default AboutUs
