import React from 'react'
import Header from '../../Components/Header/Header'
import Paralax from '../../Components/Paralax/Paralax'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'
import BodyComponent from '../../Components/BodyComponent/BodyComponent'
import TopArrow from '../../Components/Top_Arrow/TopArrow'

const DigitalMarketing = () => {
    return (
        <div className='digitalMarketing bgColor'>

            <Header forWhatPage={'Digital Marketing'} />
            <Paralax
                withSlogan={false}
                firstLine={'Social Media'}
                secondLine={'that Builds'}
                thirdLine={'Connections.'}
            />

            <BodyComponent bodyFor={'Digital Marketing'} />


            <Contact />
            <Footer forWhatPage={'Digital Marketing'} />
            <TopArrow />

        </div>
    )
}

export default DigitalMarketing
