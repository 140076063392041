import React from 'react'

const TopArrow = () => {


    const componentDidMount = () => {
        window.scrollTo(0, 0);

    }

    return (
        <div className='topArrow' onClick={() => { componentDidMount() }}>
            <img src="Assets/top_arrow.svg" alt="top arrow" />
        </div>
    )
}

export default TopArrow
