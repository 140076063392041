import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Views/Home/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ADS from './Views/ADS/ADS';
import ComunityManager from './Views/ComunityManager/ComunityManager';
import DigitalMarketin from './Views/DigitalMarketing/DigitalMarketing';
import GraphicDesign from './Views/GraphicDesign/GraphicDesign';
import WebDesign from './Views/WebDesign/WebDesign';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // Duración de la animación en milisegundos
      easing: 'ease-in-out', // Tipo de suavizado de la animación
      once: false, // Si es true, la animación se ejecuta solo una vez
      mirror: true, // Repite la animación al hacer scroll arriba/abajo
      offset: 200, // Ajuste de desplazamiento para activar la animación
    });
  }, []);

  return (

    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/ads' element={<ADS />} />
        <Route path='/community_manager' element={<ComunityManager />} />
        <Route path='/digital_marketing' element={<DigitalMarketin />} />
        <Route path='/graphic_design' element={<GraphicDesign />} />
        <Route path='/web_design' element={<WebDesign />} />

        <Route path='*' element={<h1>Error, missing page!</h1>} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
