import React from 'react'
import Title from '../Title/Title'

const WhyChooseUs = () => {
    return (
        <div className='whyChooseUs-bg' data-aos="fade-right" data-aos-delay="100" data-aos-offset="300">
            <div className='whyChooseUs'>

                <Title titleName={'Why Choose Us'} />

                <div className="whyChoose_container">
                    <div className="whyChoose_container-sectionOne">
                        <div className="sectionOne_title">
                            <h3 className='subtitle-two'>
                                Your Strategic Partner
                            </h3>
                        </div>
                        <div className="sectionOne_subtitle">
                            <p className='text-p'>
                                In advanced marketing, we combine creativity, technology, and strategy to drive your brand toward success. With us, your vision comes to life.
                            </p>
                        </div>
                    </div>
                    <div className="whyChoose_container-sectionTwo">
                        <div className="whyChoose_container-sectionTwo-info">
                            <div className="sectionTwo-info-txt d-flex-a-center" data-aos="fade-right" data-aos-delay="100">
                                <div>
                                    <h3 className='subtitle-tree'>ORIGINALITY and CREATIVITY</h3>
                                    <p className='text-p'>We distinguish ourselves with our ability to hink creatively. We apply imaginative solutions and groundbreaking strategies to make your brand unique, ensuring you stand out in a competitive landscape.</p>
                                </div>
                            </div>
                            <div className="sectionTwo-info-txt d-flex-a-center" data-aos="fade-right" data-aos-delay="300">
                                <div>
                                    <h3 className='subtitle-tree'>RESULTS ORIENTED APPROACH</h3>
                                    <p className='text-p'>Our main objective is to deliver measurable results. We concentrate on key metrics to guarantee a return on investment, fostering growth and increasing visibility for your business.</p>
                                </div>
                            </div>
                            <div className="sectionTwo-info-txt d-flex-a-center" data-aos="fade-right" data-aos-delay="300">
                                <div>
                                    <h3 className='subtitle-tree'>EXPERIENCE and KNOWLEDGE</h3>
                                    <p className='text-p'>Our team consists of seasoned professionals across various marketing disciplines. This expertise enables us to understand your needs thoroughly and provide tailored solutions that align seamlessly with your business objectives</p>
                                </div>
                            </div>
                        </div>
                        <div className="whyChoose_container-sectionTwo-img" data-aos="flip-right" data-aos-duration="1000">
                            <img src="Assets/WhyChoose-img2.png" alt="mockup" />
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default WhyChooseUs
