import React from 'react'
import TopArrow from '../../Components/Top_Arrow/TopArrow'
import Header from '../../Components/Header/Header'
import Paralax from '../../Components/Paralax/Paralax'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'
import BodyComponent from '../../Components/BodyComponent/BodyComponent'

const ComunityManager = () => {
    return (
        <div className='communityManager bgColor'>

            <Header forWhatPage={'Community Manager'} />
            <Paralax
                withSlogan={false}
                firstLine={'Taking Brands'}
                secondLine={'to the'}
                thirdLine={'Next Level.'}
            />

            <BodyComponent bodyFor={'Community Manager'} />


            <Contact />
            <Footer forWhatPage={'Community Manager'} />
            <TopArrow />


        </div>
    )
}

export default ComunityManager
