import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import Hamburger from 'hamburger-react';



const Header = ({ forWhatPage }) => {

    const [isOpen, setOpen] = useState(false)
    const [showAboutUs, setShowAboutUs] = useState(true)
    const [showProjects, setShowProjects] = useState(true)



    useEffect(() => {
        if (forWhatPage === 'ADS' || forWhatPage === 'Digital Marketing' || forWhatPage === 'Graphic Design' || forWhatPage === 'Web Design' || forWhatPage === 'Community Manager') {
            setShowAboutUs(false)
            setShowProjects(false)
        } else {
            setShowAboutUs(true)
            setShowProjects(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='header d-flex-a-center'>

            <div className="burger">

                <Hamburger toggled={isOpen} toggle={setOpen} className='hamburger' />

                {isOpen &&

                    <div className='hamburger_menu'>
                        <li><Link to={'/'}>Home</Link></li>
                        {showAboutUs && <li><a href="#About_Us">About Us</a></li>}


                        <li>
                            <DropdownButton id="dropdown-basic-button" title="Services" drop='end'>
                                <Dropdown.Item >
                                    <Link to={'/digital_marketing'}>Digital Marketing</Link>
                                </Dropdown.Item>
                                <Dropdown.Item >
                                    <Link to={'/community_manager'}>Community Manager</Link>
                                </Dropdown.Item>
                                <Dropdown.Item >
                                    <Link to={'/ads'}>ADS</Link>
                                </Dropdown.Item>
                                <Dropdown.Item >
                                    <Link to={'/web_design'}>Web Design</Link>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <Link to={'/graphic_design'}>Graphic Design</Link>
                                </Dropdown.Item>
                            </DropdownButton>
                        </li>
                        {showProjects && <li><a href="#Projects">Projects</a></li>}


                        <li><a href="#Contact">Contact</a></li>
                    </div>
                }
            </div>


            <div className="navBar d-flex-a-center menu-txt">

                <li><Link to={'/'}>Home</Link></li>
                {showAboutUs && <li><a href="#About_Us">About Us</a></li>}


                <li>
                    <DropdownButton id="dropdown-basic-button" title="Services" drop='end'>
                        <Dropdown.Item >
                            <Link to={'/digital_marketing'}>Digital Marketing</Link>
                        </Dropdown.Item>
                        <Dropdown.Item >
                            <Link to={'/community_manager'}>Community Manager</Link>
                        </Dropdown.Item>
                        <Dropdown.Item >
                            <Link to={'/ads'}>ADS</Link>
                        </Dropdown.Item>
                        <Dropdown.Item >
                            <Link to={'/web_design'}>Web Design</Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <Link to={'/graphic_design'}>Graphic Design</Link>
                        </Dropdown.Item>
                    </DropdownButton>
                </li>
                {showProjects && <li><a href="#Projects">Projects</a></li>}

                <li><a href="#Contact">Contact</a></li>



            </div>

            <div className='logo'>
                <Link to={'/'}>
                    <img src="Assets/Logo_DigitalWave.svg" alt="Digital Wave Logo" />
                </Link>
            </div>
        </div >
    )
}

export default Header
