import React from 'react'
import Title from '../Title/Title'
import CommunityData from '../../ServiceData/CommunityData.json'

const BodyCommunity = () => {
    return (
        <div className="personalizedAdvice-bg">
            <div className='personalizedAdvice' data-aos='fade-right'>

                <Title titleName={'Personalized Advice'} />

                <div className="personalizedAdvice_subtitle d-flex-col-align-center">
                    <h3 className='subtitle-tree'>Essential Elements of Our Website.</h3>
                    <p className='text-p'>Discover the key features that make our website exceptional. Built with the user experience at the forefront, our platform delivers a smooth and engaging interaction.</p>
                </div>

                <div className="personalizedItems">
                    <section className="personalizedItems_section1 d-flex-col-wrap">
                        {CommunityData.map((item, id) => {
                            return (
                                <div className="item d-flex-a-center" key={id}>
                                    <img src="Assets/community-check-icon.svg" alt="Check icon" />
                                    <h3 className='btn-h3'>{item.text}</h3>
                                </div>

                            )
                        })}
                    </section>
                </div>
            </div>
            <div className="my-bg">
                <img src="Assets/background1.svg" alt="background" />
            </div>
        </div>
    )
}

export default BodyCommunity
