import React from 'react'
import Header from '../../Components/Header/Header'
import Paralax from '../../Components/Paralax/Paralax'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'
import BodyComponent from '../../Components/BodyComponent/BodyComponent'
import TopArrow from '../../Components/Top_Arrow/TopArrow'

const ADS = () => {
    return (
        <div className='ads bgColor'>
            <Header forWhatPage={'ADS'} />
            <Paralax
                withSlogan={false}
                firstLine={'Effective'}
                secondLine={'Advertising'}
                thirdLine={'Solutions.'}
            />

            <BodyComponent bodyFor={'ADS'} />

            <Contact />
            <Footer forWhatPage={'ADS'} />
            <TopArrow />

        </div>
    )
}

export default ADS
